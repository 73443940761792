<template>
  <router-view @placeOrder="prePlaceOrder"/>
</template>

<script>
// page where paypal redirect on success
import Checkout from '@/pages/Checkout'

export default {
  mixins: [Checkout]
}
</script>
